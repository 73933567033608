import React, { useState } from 'react';
import axios from 'axios';
import './AddNews.css';
import AdminNav from './AdminNav';

const AddNews = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        content: '',
        image: null,
        category: 'general', // Set default category
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const CATEGORY_CHOICES = [
        { value: 'General', label: 'General' },
        { value: 'Technology', label: 'Technology' },
        { value: 'Sports', label: 'Sports' },
        { value: 'Politics', label: 'Politics' },
        { value: 'Health', label: 'Health' },
        { value: 'Local News', label: 'Local News' },
        { value: 'World News', label: 'World News' },
    ];

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (event) => {
        setFormData({ ...formData, image: event.target.files[0] });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        const newsData = new FormData();
        newsData.append('title', formData.title);
        newsData.append('description', formData.description);
        newsData.append('content', formData.content);
        newsData.append('category', formData.category); // Include category in form data
        if (formData.image) {
            newsData.append('image', formData.image);
        }

        try {
            console.log('Submitting news data:', formData); // Debugging line
            const response = await axios.post('https://newslive.infoxtechnologies.com/api/addNews/', newsData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.data.success) {
                setSuccessMessage('News added successfully!');
                setFormData({ title: '', description: '', content: '', image: null, category: 'general' });
            } else {
                setErrorMessage('Failed to add news. Please try again.');
            }
        } catch (error) {
            console.error('An error occurred:', error.response ? error.response.data : error.message);
            setErrorMessage('An error occurred while adding news.');
        }
    };

    return (
        <>
            <AdminNav />
            <div className="add-news-container">
                <h2 className="text-center">Add News</h2>
                {successMessage && <div className="alert success">{successMessage}</div>}
                {errorMessage && <div className="alert error">{errorMessage}</div>}
                <form onSubmit={handleSubmit} className="add-news-form">
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className="form-control"
                            id="title"
                            placeholder="Enter title"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <input
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="form-control"
                            id="description"
                            placeholder="Enter a short description"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="content">Content</label>
                        <textarea
                            name="content"
                            value={formData.content}
                            onChange={handleChange}
                            className="form-control"
                            id="content"
                            rows="6"
                            placeholder="Enter the content"
                            required
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="category">Category</label>
                        <select
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                            className="form-control"
                            id="category"
                            required
                        >
                            {CATEGORY_CHOICES.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="image">Upload Image</label>
                        <input
                            type="file"
                            name="image"
                            onChange={handleImageChange}
                            className="form-control-file"
                            id="image"
                            accept="image/*"
                        />
                    </div>
                    <button type="submit" className="btn-submit">
                        Add News
                    </button>
                </form>
            </div>
        </>
    );
};

export default AddNews;
