import "./App.css";
import "./index.css";
import React from "react";
import Navbar from "./components/Navbar";
import News from "./components/News";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useState } from "react";
import LoginPage from "./components/LoginPage";
import AdminHome from "./components/adminHome";
import AdminNav from "./components/AdminNav";
import AddNews from "./components/AddNews";
import AddAdv from "./components/AddAdv";
import AdminViewNews from "./components/AdminViewNews";
import EditNews from "./components/EditNews";
import EditAdv from "./components/EditAdv";
import AdminViewAds from "./components/ViewAdv";
import NewsDetails from "./components/NewsDetails";

const App = () => {
	const pageSize = 9;
	const apiKey = process.env.REACT_APP_NEWS_API;
	const [progress, setProgress] = useState(0);

	return (
		<div>
			<Router>
				{/* <Navbar /> */}
				<div>
					<LoadingBar color="#f11946" progress={progress} height={3} />
				</div>
				<Routes>
					{/* <Route exact path="/" element={<News setProgress={setProgress} apiKey={apiKey} key="general" pageSize={pageSize} country="in" category="general" />} />
					<Route exact path="/business" element={<News setProgress={setProgress} apiKey={apiKey} key="business" pageSize={pageSize} country="in" category="business" />} />
					<Route exact path="/entertainment" element={<News setProgress={setProgress} apiKey={apiKey} key="entertainment" pageSize={pageSize} country="in" category="entertainment" />} />
					<Route exact path="/health" element={<News setProgress={setProgress} apiKey={apiKey} key="health" pageSize={pageSize} country="in" category="health" />} />
					<Route exact path="/science" element={<News setProgress={setProgress} apiKey={apiKey} key="science" pageSize={pageSize} country="in" category="science" />} />
					<Route exact path="/sports" element={<News setProgress={setProgress} apiKey={apiKey} key="sports" pageSize={pageSize} country="in" category="sports" />} />
					<Route exact path="/technology" element={<News setProgress={setProgress} apiKey={apiKey} key="technology" pageSize={pageSize} country="in" category="technology" />} /> */}
					<Route path="/" element={<News/>}></Route>
					<Route path="/login" element={<LoginPage/>}></Route>
					<Route path="/login/admin-home" element={<AdminHome />}></Route>
					<Route path="/AdminNav" element={<AdminNav />}></Route>
					<Route path="/AddNews" element={<AddNews />}></Route>
					<Route path="/AddAdv" element={<AddAdv />}></Route>
					<Route path="/ViewNews" element={<AdminViewNews />}></Route>
					<Route path="/ViewAdv" element={<AdminViewAds />}></Route>
					<Route path="/edit-news/:id" element={<EditNews />}></Route>
					<Route path="/edit-adv/:id" element={<EditAdv />}></Route>
					<Route path="/news-detail/:id" element={<NewsDetails />} />
				</Routes>
			</Router>
		</div>
	);
};

export default App;
