import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './NewsDetails.css'; // Your custom styles
import Navbar from './Navbar';

function NewsDetails() {
    const { id } = useParams(); // Get the news ID from the URL
    const [newsDetail, setNewsDetail] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch news details by ID
        const fetchNewsDetail = async () => {
            try {
                const response = await axios.get(`https://newslive.infoxtechnologies.com/api/newsdetails/${id}/`); // Replace with your API endpoint
                console.log(response.data); // Log to check the response
                setNewsDetail(response.data); // Assuming the API returns the news data
            } catch (error) {
                setErrorMessage('Failed to fetch news details. Please try again later.');
                console.error(error);
            }
        };

        fetchNewsDetail();
    }, [id]);

    if (errorMessage) {
        return <div className="alert alert-danger">{errorMessage}</div>;
    }

    if (!newsDetail) {
        return <div>Loading...</div>; // Display loading state until data is fetched
    }

    return (
        <>
        <Navbar/>
        <div className="news-details-containerr mt-5">
            <button className="back-btn" onClick={() => navigate('/')}>Back to News</button>
            <h2>{newsDetail.title}</h2>
            <img src={newsDetail.image_url} alt={newsDetail.title} className="news-image" />
            <p className="news-descriptionn">{newsDetail.description}</p>
            {/* If content contains HTML, render it safely using dangerouslySetInnerHTML */}
            <div className="news-contentt" dangerouslySetInnerHTML={{ __html: newsDetail.content }}></div> 
            <div className="news-meta">
                <span>{new Date(newsDetail.date_published).toLocaleDateString()}</span>
            </div>
        </div>
        </>
    );
}

export default NewsDetails;
