import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import Navbar from './Navbar';
import './LoginPage.css';

const LoginPage = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const loginResponse = await axios.post('https://newslive.infoxtechnologies.com/api/loginUser/', formData);
            if (loginResponse.data.success) {
                navigate('admin-home')
            } else {
                setErrorMessage(loginResponse.data.message || 'Invalid username or password');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setErrorMessage('Invalid username or password');
        }
    };

    return (
        <>
        <Navbar />
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="container mt-5">
                        <div style={{ marginTop: 150 }} className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header bg-black text-center text-white">
                                        <h3>News24 Login</h3>
                                    </div>
                                    <div className="card-body">
                                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                        <div className="form-group">
                                            <label htmlFor="username">Username</label>
                                            <input
                                                type="text"
                                                name="username"
                                                value={formData.username}
                                                onChange={handleChange}
                                                className="form-control"
                                                id="username"
                                                placeholder="Enter username"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                className="form-control"
                                                id="password"
                                                placeholder="Password"
                                            />
                                        </div>
                                        <div className="form-group mt-2">
                                            <button type="submit" className="btn btn-dark btn-block">
                                                Login
                                            </button>
                                        </div>
                                        {/* <div className="form-group text-center">
                                            <a href="/forgot-password">Forgot Password?</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default LoginPage;
